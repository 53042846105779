<script setup lang="ts">
const { label, icon, isSubmitting } = withDefaults(
  defineProps<{
    name: string
    label?: string
    icon?: string
    placeholder?: string
    isSubmitting?: boolean
    cols?: number
    type?: string
  }>(),
  {
    label: undefined,
    icon: undefined,
    placeholder: undefined,
    isSubmitting: false,
    cols: 12,
    type: undefined,
  },
)
</script>

<template>
  <div :class="`col-span-${cols}`">
    <Field v-slot="{ field, errorMessage }" :name="name">
      <BaseInput
        :icon="icon"
        :label="label"
        :placeholder="placeholder"
        :error="errorMessage"
        :disabled="isSubmitting"
        v-bind="field"
        :type="type"
      />
    </Field>
  </div>
</template>
